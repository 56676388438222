<template>
  <div class="container">
    <div class="img">
      <img src="../../static/img/banner_02.jpg" alt="">
    </div>
    <div class="text">
      <p class="txt">公司位于临沂市兰山经济开发区，多年来始终坚持技术创新、管理创新、模式创新、服务创新、产品创新、组织创新六大创新，并已连续六年被评为国内最大的安全产品供应商。 公司主要从事安全防护用品、工业/商用清洁用品、安全仪器仪表、消防装备、安防产品等品类销售，通过整合全球品牌，形成了8大类，185个子类，共计10万余 SKU 的工业安全用品产品线。</p>
      <p class="txt">依托云计算、大数据、物联网、区块链等新一代信息技术，借助公司自主研发的NEBULA星云系统、六大平台和易安捷商城，为全国12万+渠道用户和全国5000余家终端企业用户提供产品解决方案、系统对接、售后服务、供应链统筹与规划、仓储管理、物流管理、信息技术服务、金融支持以及商务管理等方面的供应链技术服务，并在该领域经山东省质量评价协会认定为山东省优质品牌服务。</p>
      <p class="txt">易安捷将持续加强各地分仓和办事处团队建设，在成都、北京、上海、乌鲁木齐、青岛分别设立分公司和办事处，为客户提供更加高效、便捷的服务。</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyIntroduce",
  created() {
    this.$store.dispatch('changeLocation', '公司介绍')
  },
}
</script>

<style scoped lang="scss">
.container{
  padding-bottom: 50px;
  .img{
    padding: 15px 0;
    img{
      width: 86%;
      //height: 236px;
      margin: 0 auto;
      display: block;
    }
  }
  .text{
    box-sizing: border-box;
    padding: 0 15px;
    box-shadow: 0px 1px 7px 0px rgba(153, 153, 153, 0.09);
    .txt{
      font-size: 15px;
      font-family: Microsoft YaHei;
      font-weight: normal;
      color: #666666;
      text-indent: 2em;
      line-height: 22px;
      text-align: justify;
      margin-top: 8px;
      margin-bottom: 0;
    }
  }
}
</style>
